<template>
    <div>
        <b-card v-for="(status,index) in inputVal.statuses"
                :title="getStatuses()[status.status] +' ('+status.status+')'"
                :key="index"
        >
            <b-card-text>
                <condition v-for="(rule,condition_index) in status.conditions"
                           :key="condition_index"
                           :index="condition_index"
                           v-model="status.conditions[condition_index]"
                           :attributes="getAttributes() | optionsVV"
                           :conditions="getConditions()"
                           :time-types="getTimeTypes()"
                           :remove="()=>{remove(index,condition_index)}"
                           :attributeModel="'offering'"
                ></condition>

                <b-button variant="success"
                          @click="addCondition(index)"
                          :title="$t('common.title.add')"
                          class="mr-2"
                >
                    <font-awesome-icon icon="plus"/>
                    {{ $t('settings.workflow.button.add_condition') }}
                </b-button>
                <b-button variant="danger" @click="removeStatus(index)"
                          :title="$t('common.title.delete')">
                    <font-awesome-icon icon="trash"/>
                    {{ $t('settings.workflow.button.remove_status_detection') }}
                </b-button>
            </b-card-text>
        </b-card>

        <b-row>
            <b-col>
                <zw-select-group v-model="selectStatus"
                                 :options="getStatuses() | optionsStatuses"
                                 name="status"
                                 :label-prefix="labelPrefix"
                                 validate="required"
                                 disable-label
                ></zw-select-group>
            </b-col>
            <b-col>
                <b-button :disabled="!selectStatus"
                          @click="addStatus()"
                          variant="success"
                >
                    <font-awesome-icon icon="plus"/>
                    {{ $t('settings.workflow.button.add_status_detection') }}
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Condition from "@/components/Condition";

export default {
    name: 'DetectStatusOptions',
    components: {Condition},
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
            selectStatus: false,
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getStatuses']),
        ...mapGetters('Workflow', ['getAttributes', 'getConditions', 'getTimeTypes']),
        addStatus() {
            let statuses = this.inputVal.statuses || []
            statuses.push({
                status: this.selectStatus,
                conditions: [{}]
            })
            this.$set(this.inputVal, 'statuses', statuses)
        },
        remove(status_index, condition_index) {
            let conditions = this.inputVal.statuses[status_index].conditions.filter((data, i) => {
                return i != condition_index
            });
            this.$set(this.inputVal.statuses[status_index], 'conditions', conditions)

        },
        removeStatus(index) {
            let statuses = this.inputVal.statuses.filter((data, i) => {
                return i != index
            });
            this.$set(this.inputVal, 'statuses', statuses)
        },
        addCondition(status_index) {
            let conditions = this.inputVal.statuses[status_index].conditions
            conditions.push({})
            this.$set(this.inputVal.statuses[status_index], 'conditions', conditions)
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },

    },
}
</script>